import { IServer, IServerData } from ".";

export class Server implements IServer {
  readonly zamID: string;
  readonly zamName: string;
  readonly totalIP4s: number;
  disabledIP4s: number;
  offlineIP4s: number;
  overusedIP4s: number;
  readonly primaryIP4: string;
  readonly firstSeen: number;
  readonly company: string;

  readonly firstSeenDate: string;

  constructor(data: IServerData) {
    this.zamID = data.zamID;
    this.zamName = data.zamName;
    this.totalIP4s = data.totalIP4s;
    this.primaryIP4 = data.primaryIP4;
    this.firstSeen = data.firstSeen;
    this.company = data.company;
    this.disabledIP4s = data.disabledIP4s;
    this.overusedIP4s = data.overusedIP4s;
    this.offlineIP4s = data.offlineIP4s;

    this.firstSeenDate = new Date(this.firstSeen).toLocaleString("en-GB");
  }
}
