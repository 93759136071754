import { IServer } from "@/entities";
import { IIPsProvider } from "@/services/provider.types";
import Vue from "vue";

export const IPExpandProps = Vue.extend({
  props: {
    dynamicColor: Function,
    server: Object as () => IServer,
    ipsProvider: Object as () => IIPsProvider,
  },
});
