import { IServer } from "@/entities";
import { IIPsProvider } from "@/services/provider.types";
import Vue from "vue";

export const ServersTableProps = Vue.extend({
  props: {
    servers: Array as () => IServer[],
    ipsProvider: Object as () => IIPsProvider,
  },
});
