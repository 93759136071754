import { IOfflineIP, IOfflineIPData } from ".";
import { Status } from "..";
import { IStatus } from "../status/status.types";

export class OfflineIP implements IOfflineIP {
  readonly address: string;
  readonly firstSeen: number;
  readonly isInZam: boolean;
  readonly zamServerID: string;

  readonly availabilityStatuses: IStatus[];
  readonly firstSeenDate: string;

  constructor(data: IOfflineIPData) {
    this.address = data.address;
    this.firstSeen = data.firstSeen;
    this.isInZam = data.isInZam;
    this.zamServerID = data.zamServerID;

    this.availabilityStatuses = data.availabilityStatuses.map(
      (avStatus) => new Status(avStatus)
    );
    this.firstSeenDate = new Date(data.firstSeen).toLocaleString("en-GB");
  }
}
