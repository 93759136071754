import { IIPsProvider, IServersProvider } from "./provider.types";
import { ServerService } from ".";
import { OfflineIPService } from "./offlineIPs/offlineIPs";
import { IOfflineIP } from "@/entities";

export const serversProvider = async (
  offlineIPs: IOfflineIP[]
): Promise<IServersProvider> => ({
  servers: await ServerService.initialize(offlineIPs),
});

export const ipsProvider = async (): Promise<IIPsProvider> => ({
  ips: await OfflineIPService.initialize(),
});