
import Component from "vue-class-component";
import { TableHeaderProps } from "./tableHeader.types";

@Component
export default class TableHeader extends TableHeaderProps {
  refreshData(): void {
    this.$emit("refresh", this.$props.company);
  }
}
