<template>
  <v-app>
    <v-app-bar id="bar" app dark>
      <v-card-title> Offline Instances Tool </v-card-title>
    </v-app-bar>

    <v-main class="content">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Component from "vue-class-component";
import Vue from "vue";
import provider from "@/services";
Vue.prototype.$provider = async () => await provider();

@Component
export default class App extends Vue {}
</script>

<style scoped>
.content {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 1%;
  margin-top: 1%;
}

#bar {
  color: rgb(77, 74, 74);
  background: rgb(223, 222, 222);
}
</style>
