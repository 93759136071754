import { IStatus, IStatusData } from "./status.types";

export class Status implements IStatus {
  readonly protocolName: string;
  readonly location: string;
  readonly status: string;
  readonly rawTimestamp: number; //milliseconds
  readonly timestamp: Date;

  constructor(data: IStatusData) {
    this.protocolName = data.protocolName;
    this.location = data.location;
    this.status = data.status;
    this.rawTimestamp = data.rawTimestamp;
    this.timestamp = new Date(data.rawTimestamp);
  }
}
