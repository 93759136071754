
/* eslint-disable @typescript-eslint/ban-types */
import { IOfflineIP, IServer } from "@/entities";
import { IIPsProvider } from "@/services/provider.types";
import Component from "vue-class-component";
import Legend from "../legend/Legend.vue";
import IPExpand from "../offlineIP/IPExpand.vue";
import { ServersTableProps } from "./server.types";

@Component({
  components: {
    IPExpand,
    Legend,
  },
})
export default class ServersTable extends ServersTableProps {
  expanded = [];
  singleExpand = false;
  search = "";
  dialog = false;
  on = false;
  attrs = [];
  provider = this.$props.ipsProvider;
  

  red = { background: `#C62828`, color: `white` };
  orange = { background: `#F57C00`, color: `white` };
  yellow = { background: `#FBC02D`, color: `white` };

  legendTitles = ["Offline IPs", "Offline from"];
  legendText = [
    ["<25% of Total IPs", "<1h"],
    ["25%-50% of Total IPs", "1-6h"],
    ["50%-75% of Total IPs", "6-24h"],
    [">75% of Total IPs", ">24h"],
  ];
  legendColors = ["#f2f2f2", "#FBC02D", "#F57C00", "#C62828"];

  
  headers = [
    {
      text: "Name",
      sortable: true,
      value: "zamName",
    },
    {
      text: "Primary IP4 Address",
      sortable: true,
      value: "primaryIP4",
    },
    {
      text: "Total IPs",
      sortable: true,
      value: "totalIP4s",
    },
    {
      text: "Offline IPs",
      sortable: true,
      value: "offlineIP4s",
    },
    {
      text: "Overused IPs",
      sortable: true,
      value: "overusedIP4s",
    },
    {
      text: "Disabled IPs",
      sortable: true,
      value: "disabledIP4s",
    },
    {
      text: "Offline from",
      sortable: true,
      value: "firstSeenDate",
    },
  ];

  getTotal(srv: IServer): number {
    return srv.offlineIP4s + srv.overusedIP4s + srv.disabledIP4s
  }


  dynamicColorOnDate(firstSeen: number): object {
    let now = Date.now();
    let timePassedMilSec = now - firstSeen;
    let timePassedHours = Math.floor(timePassedMilSec / 1000 / 60 / 60);


    if (timePassedHours > 24) {
      return this.red;
    }

    if (timePassedHours >= 6) {
      return this.orange;
    }

    if (timePassedHours >= 1) {
      return this.yellow;
    }

    return {};
  }

  dynamicColorOnIP(offline: number, total: number): object {
    if (offline > 0.75 * total) {
      return this.red;
    }

    if (offline >= 0.5 * total) {
      return this.orange;
    }

    if (offline >= 0.25 * total) {
      return this.yellow;
    }

    return {};
  } 

}
