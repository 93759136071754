
import Component from "vue-class-component";
import { LegendProps } from "./legend.types";

@Component
export default class Legend extends LegendProps {
  statuses = [ {
    text: "Online",
    icon: "check",
    style: {color: "#82a936"}
  }, 
  {
    text: "Offline",
    icon: "close",
    style: {color: "#C62828"}
  },
  {
    text: "Overused/Disabled",
    icon: "mdi-cancel",
    style: {color: "#C62828"}
  }]
}
