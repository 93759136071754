
import Component from "vue-class-component";
import { IOfflineIP, IStatus, OfflineIP } from "@/entities";
import Status from "../status/Status.vue";
import { IPExpandProps } from "./offlineIP.types";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    Status
  },
})
export default class IPExpand extends IPExpandProps {
  offlineIPs: IOfflineIP[] = [];
  disabledIPs: IOfflineIP[] = [];
  overusedIPs: IOfflineIP[] = [];
  allIPs: IOfflineIP[] = [];
  statuses: string[] = [];
  locations: string[] = [];
  protocols: string[] = ["ikev2", "ovpntcp", "ovpnudp", "wg"]

  getProtocolStatus(ip: IOfflineIP, protocol: string): Array<[string, string]> {
    let protocolStatus: [string, string][] = [];
    if (this.getStatus(ip) !== "reachable") {
      this.locations.forEach((loc: string) =>
        protocolStatus.push(["unreachable", loc])
      );
      return protocolStatus;
    }

    const availabilityStatus = ip.availabilityStatuses;
    let protocolAvailability: IStatus[] = availabilityStatus.filter(
      (avStat) => avStat.protocolName === protocol
    );

    this.locations.forEach((loc: string) => {
      let testLoc = protocolAvailability.filter((ps) => ps.location === loc);

      if (testLoc.length === 0) {
        protocolStatus.push(["online", loc]);
      } else {
        const status = testLoc[0].status;
        protocolStatus.push([status, loc]);
      }
    });

    return protocolStatus;
  }

  getStatus(ip: OfflineIP): string {
    for (let i = 0; i < ip.availabilityStatuses.length; i++) {
      const avStat = ip.availabilityStatuses[i].status;
      if (avStat !== "online" && avStat !== "offline") {
        return avStat;
      }
    }

    return "reachable";
  }

  getHeaders(): string[] {
    const headers: string[] = ["IP Address", "Is in ZAM", "Status"];
    headers.push(...this.protocols);
    headers.push("First seen");

    return headers;
  }

  async mounted() {
    this.fetchData();
  }

  @Watch("server")
  onUpdateChange() {
    this.fetchData(); 
  }

  fetchData() {
    const ips = this.$props.ipsProvider.ips.getOfflineIPsForServer(this.server.zamID);
    ips.forEach((ip: IOfflineIP) => {
      const status = this.getStatus(ip);
      console.log(status)
      if( status === "reachable") {
        this.offlineIPs.push(ip)
      }
       if(status === "disabled") {
        this.disabledIPs.push(ip)
      }
       if(status === "overused") {
        this.overusedIPs.push(ip)
      }
    });

    this.offlineIPs.sort((a,b) => (a.address > b.address) ? 1 : ((b.address > a.address) ? -1 : 0));
    this.overusedIPs.sort((a,b) => (a.address > b.address) ? 1 : ((b.address > a.address) ? -1 : 0));
    this.disabledIPs.sort((a,b) => (a.address > b.address) ? 1 : ((b.address > a.address) ? -1 : 0));
    
    this.allIPs.push(...this.offlineIPs)
    this.statuses.push(...Array(this.offlineIPs.length).fill("reachable"))
    this.allIPs.push(...this.overusedIPs)
    this.statuses.push(...Array(this.overusedIPs.length).fill("overused"))
    this.allIPs.push(...this.disabledIPs)
    this.statuses.push(...Array(this.disabledIPs.length).fill("disabled"))


    this.locations = this.$props.ipsProvider.ips.getLocations();
    this.locations.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
  }

  getOffline(ips: IOfflineIP[]): IOfflineIP[] {
    return ips.filter(i => i.availabilityStatuses[0].status !== "overused" && i.availabilityStatuses[0].status !== "disabled")
  }
  
  getDisabled(ips: IOfflineIP[]): IOfflineIP[] {
    return ips.filter(i => i.availabilityStatuses[0].status === "disabled")
  }

  getOverused(ips: IOfflineIP[]): IOfflineIP[] {
    return ips.filter(i => i.availabilityStatuses[0].status === "overused")
  }

}
