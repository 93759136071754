import Vue from "vue";
import { router, vuetify } from "./ui";
import App from "@/ui/App.vue";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import axios from "axios";

Vue.config.productionTip = false;
axios.defaults.headers.post["Authorization"] = (window as any).configs.VUE_APP_TOKEN;
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.baseURL = (window as any).configs.VUE_APP_BASE_URL;

// for development
// axios.defaults.headers.post["Authorization"] = process.env.VUE_APP_TOKEN;
// axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
