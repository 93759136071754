import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./pages/home/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    metaTags: {
      name: "Offline Instances"
    }
  },
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
