
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from "vue";
import Component from "vue-class-component";
import ServersTable from "@/ui/components/server/ServersTable.vue";
import Legend from "@/ui/components/legend/Legend.vue";
import TableHeader from "@/ui/components/tableHeader/TableHeader.vue";
import { ipsProvider, serversProvider } from "@/services/provider";
import { IOfflineIP, IServer } from "@/entities";
import { IIPsProvider, IServersProvider } from "@/services/provider.types";

@Component({
  components: {
    ServersTable,
    Legend,
    TableHeader,
  },
})
export default class Home extends Vue {
  servers: Record<string, IServer[]> = {};
  totalOffline: Record<string, number> = {};
  lastUpdated: Record<string, string> = {};
  companies: string[] = [];
  prv!: IServersProvider;
  ipsPrv!: IIPsProvider ;
  interval!: number;
  render = "loading";

  created() {
    this.refreshInterval();
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }

  async mounted() {
    this.ipsPrv = await ipsProvider();
    this.prv = await serversProvider(this.ipsPrv.ips.getOfflineIPs());
    

    this.companies = this.prv.servers.getAllCompanies();
    this.companies.forEach(async (el: string) => {
      this.servers[el] = this.prv.servers.getAllPerCompany(el);
      this.totalOffline[el] = this.prv.servers.getTotalOfflinePerCompany(el);
      this.lastUpdated[el] = new Date(Date.now()).toLocaleString("en-GB");
    });

  
    if(this.companies.length !== 0) {
      this.render = "loaded-data";
    } else {
      this.render = "no-data";
    }
  }

  refreshInterval() {
    this.interval = setInterval(this.handleRefresh, 90000);
  }

  async handleRefresh() {
    await this.ipsPrv.ips.refresh();
    await this.prv.servers.refresh(this.ipsPrv.ips.getOfflineIPs());
    this.companies.forEach((company: string) => {
      this.servers[company] = this.prv.servers.getAllPerCompany(company);
      this.totalOffline[company] =
        this.prv.servers.getTotalOfflinePerCompany(company);
        this.lastUpdated[company] = new Date(Date.now()).toLocaleString("en-GB");
    });
    this.$forceUpdate();
  }
}
