
import Component from "vue-class-component";
import { StatusProps } from "./status.types";

@Component
export default class Status extends StatusProps {
  checkStatus(status: string): boolean {
    if (status === "online") {
      return true;
    }
    return false;
  }
}
