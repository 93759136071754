import { IOfflineIP, IServer, IServerData, Server } from "@/entities";
import axios from "axios";
import { IServerService } from "./servers.types";

export class ServerService implements IServerService {
  private serversData!: IServerData[];

  constructor(data: IServerData[]) {
    this.serversData = data;
  }

  static async initialize(offIPs: IOfflineIP[]): Promise<IServerService> {
    const data = await ServerService.serversQuery();
    for (let i = 0; i < data.length; i++) {
      data[i].disabledIP4s = ServerService.getDisabled(data[i].zamID, offIPs);
      data[i].overusedIP4s = ServerService.getOverused(data[i].zamID, offIPs);
      data[i].offlineIP4s = ServerService.getOffline(data[i].zamID, offIPs);
    }
    return new ServerService(data);
  }

  static getDisabled(serverID: string, offIPs: IOfflineIP[]): number {
    const disabled = offIPs.filter(
      (oi) => oi.zamServerID === serverID && this.ipStatus(oi) === "disabled"
    );
    return disabled.length;
  }

  static getOverused(serverID: string, offIPs: IOfflineIP[]): number {
    const overused = offIPs.filter(
      (oi) => oi.zamServerID === serverID && this.ipStatus(oi) === "overused"
    );
    return overused.length;
  }
  static getOffline(serverID: string, offIPs: IOfflineIP[]): number {
    const offline = offIPs.filter(
      (oi) => oi.zamServerID === serverID && this.ipStatus(oi) === "reachable"
    );

    return offline.length;
  }

  static ipStatus(ip: IOfflineIP): string {
    for (let i = 0; i < ip.availabilityStatuses.length; i++) {
      if (
        ip.availabilityStatuses[i].status !== "online" &&
        ip.availabilityStatuses[i].status !== "offline"
      ) {
        return ip.availabilityStatuses[i].status;
      }
    }

    return "reachable";
  }

  async refresh(offIPs: IOfflineIP[]): Promise<void> {
    const data = await ServerService.serversQuery();
    for (let i = 0; i < data.length; i++) {
      data[i].disabledIP4s = ServerService.getDisabled(data[i].zamID, offIPs);
      data[i].overusedIP4s = ServerService.getOverused(data[i].zamID, offIPs);
      data[i].offlineIP4s = ServerService.getOffline(data[i].zamID, offIPs);
    }
    this.serversData = data;
  }

  static serversQuery(): Promise<IServerData[]> {
    return new Promise((resolve, reject) => {
      const url = (window as any).configs.VUE_APP_ENDPOINT;
      //const url = process.env.VUE_APP_ENDPOINT;
      const query =
        "query  {\n  servers(filter:{\n NOT: {\n totalOfflineIP4s: {\n match: 0 \n} \n}\n}\n) {\n    zamID,\n    zamName,\n    primaryIP4,\n    totalIP4s,\n  totalOfflineIP4s,\n   company,\n    firstSeen,\n }\n}\n";
      axios
        .post(url, { query })
        .then((res) => resolve(res.data.data.servers))
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllCompanies(): string[] {
    const allComp: string[] = [];
    this.serversData.forEach((srv) => {
      if (allComp.find((el) => el === srv.company) === undefined) {
        allComp.push(srv.company);
      }
    });

    return allComp;
  }

  getAll(): IServer[] {
    return this.serversData.map((srv) => new Server(srv));
  }

  getAllPerCompany(company: string): IServer[] {
    const serversData = this.serversData.filter(
      (srv) => srv.company === company && srv.offlineIP4s !== 0
    );

    const servers = serversData.map((srv) => new Server(srv));
    return servers;
  }

  getTotalOffline(): number {
    const servers = this.getAll();
    let totalOffline = 0;

    servers.forEach((srv) => {
      totalOffline = totalOffline + srv.offlineIP4s;
    });

    return totalOffline;
  }

  getTotalOfflinePerCompany(company: string): number {
    const serversCompany = this.serversData.filter(
      (srv) => srv.company === company
    );
    let offline = 0;

    serversCompany.forEach((srvData) => {
      const srv = new Server(srvData);
      offline = offline + srv.offlineIP4s;
    });

    return offline;
  }

  getOneById(id: string): IServer | undefined {
    const data = this.serversData.find((server) => server.zamID === id);
    if (!data) {
      return undefined;
    }

    return new Server(data);
  }
}
