import { IOfflineIP, IOfflineIPData, OfflineIP } from "@/entities";
import axios from "axios";
import { IOfflineIPService } from "./offlineIPs.types";

export class OfflineIPService implements IOfflineIPService {
  private offlineIPs!: IOfflineIPData[];

  constructor(data: IOfflineIPData[]) {
    this.offlineIPs = data;
  }

  static async initialize(): Promise<IOfflineIPService> {
    const data = await OfflineIPService.ipsQuery();
    return new OfflineIPService(data);
  }

  async refresh(): Promise<void> {
    const ips = await OfflineIPService.ipsQuery();
    this.offlineIPs = ips;
  }

  static ipsQuery(): Promise<IOfflineIPData[]> {
    return new Promise((resolve, reject) => {
      const url = (window as any).configs.VUE_APP_ENDPOINT;
      //const url = process.env.VUE_APP_ENDPOINT;
      const query =
        'query{ \n  offlineIP4s (filter: { \n availabilityStatuses: { \n anyMatch: { \n status: {\n matchAny: [ \n "offline", "overused", "disabled" \n] \n}\n}\n}\n}\n) {\n     address,\n      isInZam,\n  zamServerID,\n    firstSeen,\n      availabilityStatuses{\n        protocolName,\n        location, \n        status\n         }\n  }\n}\n\n\n\n\n\n';
      axios
        .post(url, { query })
        .then((res) => resolve(res.data.data.offlineIP4s))
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOfflineIPsForServer(serverID: string): IOfflineIP[] {
    const offlineIPs = this.offlineIPs.filter(
      (ip) => OfflineIPService.isOffline(ip) === true && ip.zamServerID === serverID
    );
    return offlineIPs.map((oi) => new OfflineIP(oi));
  }

  getOfflineIPs(): IOfflineIP[] {
    return this.offlineIPs.map((oi) => new OfflineIP(oi));
  }

  static isOffline(ip: IOfflineIPData): boolean {
    for (let i = 0; i < ip.availabilityStatuses.length; i++) {
      if (ip.availabilityStatuses[i].status !== "online") {
        return true;
      }
    }

    return false;
  }

  getLocations(): string[] {
    return ['Singapore', 'Ohio', 'Ireland'];
    // const locations: string[] = [];
    // this.offlineIPs[0].availabilityStatuses.forEach(
    //   (avStat: { location: string }) => {
    //     if (locations.find((el) => el === avStat.location) === undefined) {
    //       locations.push(avStat.location);
    //     }
    //   }
    // );
    //
    // return locations;
  }

  getProtocols(): string[] {
    const protocols: string[] = [];

    this.offlineIPs[0].availabilityStatuses.forEach((avStat) => {
      if (protocols.find((el) => el === avStat.protocolName) === undefined) {
        protocols.push(avStat.protocolName);
      }
    });

    return protocols;
  }
}
